/* @import '../../styles/propertySets.css'; */
.select {
    margin-top: 1rem;
    width: auto;
    @media (--viewportMedium) {
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .normalSelect {
    width: 150px;
    margin-top: 18px;
    font-size: 14px;
    color: var(--matterColor);
    @media (--viewportMedium) {
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .normalSelectLandingPage {
    width: 100px;
    margin-top: 10px;
    font-size: 14px;
    color: var(--matterColor);
    @media (--viewportMedium) {
      color: white;
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .label {
    font-size: 14px;
  }